package ru.workinprogress.feature.products.domain

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*
import ru.workinprogress.feature.products.Product
import ru.workinprogress.feature.products.ProductsResource
import ru.workinprogress.flowRepository.BaseFlowRepository
import ru.workinprogress.flowRepository.DataSource

class ProductsRepository(dataSource: ProductsNetworkDataSource) : BaseFlowRepository<Product>(dataSource)

class ProductsNetworkDataSource(private val httpClient: HttpClient) : DataSource<Product> {
    override suspend fun create(params: Product): Product {
        return httpClient.post(ProductsResource()) { setBody(params) }.body()
    }

    override suspend fun load(): List<Product> {
        return httpClient.get(ProductsResource()).body()
    }

    override suspend fun update(params: Product): Product? {
        return httpClient.patch(ProductsResource.ById(id = params.id)) { setBody(params) }.body()
    }

    override suspend fun delete(id: String): Boolean {
        return httpClient.delete(ProductsResource.ById(id = id)).status == HttpStatusCode.OK
    }

}