package ru.workinprogress.shop.navigation


enum class ShopScreen {
    Preload,
    Cart,
    Main,
    Login,
    SignUp,
    Product,
}

fun ShopScreen.title() = when (this) {
    ShopScreen.Main -> "Home"
    ShopScreen.Cart -> "Your cart"
    ShopScreen.Product -> "Product"
    else -> ""
}