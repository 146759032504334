package ru.workinprogress.feature.cart

import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.lifecycle.ViewModel
import androidx.lifecycle.compose.collectAsStateWithLifecycle
import androidx.lifecycle.viewModelScope
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import org.koin.compose.viewmodel.koinViewModel
import org.koin.core.module.dsl.singleOf
import org.koin.core.module.dsl.viewModelOf
import org.koin.dsl.bind
import org.koin.dsl.module
import ru.workinprogress.feature.orders.CreateOrderUseCase
import ru.workinprogress.useCase.EmptyParams
import ru.workinprogress.useCase.NonParameterizedUseCase
import ru.workinprogress.useCase.UseCase

val cartModule = module {
    singleOf(::CartRepositoryImpl).bind<CartRepository>()
    singleOf(::GetCartUseCase)
    singleOf(::SaveCartUseCase)

    viewModelOf(::CartViewModel)
}

class CartRepositoryImpl(private val httpClient: HttpClient) : CartRepository {
    override suspend fun getCart(): CartEntity {
        return httpClient.get(CartResource()).body()
    }

    override suspend fun saveCart(cart: CartEntity): Boolean {
        return httpClient.post(CartResource()) { setBody(cart) }.status == HttpStatusCode.OK
    }
}

interface CartRepository {
    suspend fun getCart(): CartEntity
    suspend fun saveCart(cart: CartEntity): Boolean
}

class SaveCartUseCase(
    private val cartRepository: CartRepository,
) : UseCase<CartEntity, Boolean>() {
    override suspend fun invoke(params: CartEntity): Result<Boolean> {
        return withTry { cartRepository.saveCart(params) }
    }
}

class GetCartUseCase(
    private val cartRepository: CartRepository,
) : NonParameterizedUseCase<CartEntity>() {

    override suspend fun invoke(params: EmptyParams): Result<CartEntity> {
        return withTry {
            cartRepository.getCart()
        }
    }
}

data class CartUiState(val cart: CartEntity?)

class CartViewModel(
    private val getCartUseCase: GetCartUseCase,
    private val createOrderUseCase: CreateOrderUseCase,
) : ViewModel() {


    private val state = MutableStateFlow(CartUiState(null))
    val observe = state.asStateFlow()

    private lateinit var cart: CartEntity

    init {
        viewModelScope.launch {
            cart = getCartUseCase.get()

            state.update { CartUiState(cart) }
        }
    }

    fun onOrderClicked() {
        viewModelScope.launch{
            createOrderUseCase()
        }

    }
}


@Composable
fun CartComponent() {
    val viewModel = koinViewModel<CartViewModel>()
    val state by viewModel.observe.collectAsStateWithLifecycle()

    state.let { state ->
        Text(state.cart?.items?.joinToString() ?: "")
    }

    Button({ viewModel.onOrderClicked() }) {
        Text("Order now")
    }
}
