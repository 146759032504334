package ru.workinprogress.feature.products.domain

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.resources.post
import io.ktor.client.request.setBody
import ru.workinprogress.feature.products.CreateProductParams
import ru.workinprogress.feature.products.Product
import ru.workinprogress.feature.products.ProductsResource
import ru.workinprogress.useCase.UseCase

class CreateProductUseCase(private val httpClient: HttpClient) : UseCase<CreateProductParams, Product>() {
    override suspend fun invoke(params: CreateProductParams): Result<Product> {
        return withTry {
            httpClient.post(ProductsResource()) { setBody(params) }.body()
        }
    }
}