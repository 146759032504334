package ru.workinprogress.shop

import org.koin.core.module.Module
import org.koin.dsl.module
import ru.workinprogress.data.AppName
import ru.workinprogress.data.networkModule
import ru.workinprogress.feature.auth.authModule
import ru.workinprogress.feature.cart.cartModule
import ru.workinprogress.feature.orders.ordersModule
import ru.workinprogress.feature.products.productsModule


val appModules: List<Module> = listOf(
    module {
        single { AppName("shop") }
    },
    networkModule,
    authModule,
    productsModule,
    cartModule,
    ordersModule
)

