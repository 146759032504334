package ru.workinprogress.feature.products.domain

import ru.workinprogress.feature.products.Product
import ru.workinprogress.useCase.EmptyParams
import ru.workinprogress.useCase.NonParameterizedUseCase

class GetProductsUseCase(private val repo: ProductsRepository) :
    NonParameterizedUseCase<List<Product>>() {
    override suspend fun invoke(params: EmptyParams): Result<List<Product>> {
        return withTry {
            repo.load()
            repo.dataStateFlow.value
        }
    }
}