package ru.workinprogress.feature.products.ui.model

import kotlinx.collections.immutable.ImmutableList
import kotlinx.collections.immutable.persistentListOf
import ru.workinprogress.feature.products.Product

data class ProductsUiState(
    val products: ImmutableList<Product> = persistentListOf(),
    val loading: Boolean = true,
    val errorMessage: String? = null,
)