package ru.workinprogress.feature.orders

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.module
import ru.workinprogress.data.ServerException
import ru.workinprogress.feature.cart.CartEntity
import ru.workinprogress.feature.cart.CartRepository
import ru.workinprogress.flowRepository.BaseFlowRepository
import ru.workinprogress.flowRepository.DataSource
import ru.workinprogress.useCase.EmptyParams
import ru.workinprogress.useCase.NonParameterizedUseCase

val ordersModule = module {
    singleOf(::OrdersNetworkDataSource)
    singleOf(::OrdersRepository)
    singleOf(::CreateOrderUseCase)

}

class OrdersNetworkDataSource(private val httpClient: HttpClient) : DataSource<Order> {

    override suspend fun create(params: Order): Order {
        return httpClient.post(OrdersResource()) { setBody(params) }.body()
    }

    override suspend fun load(): List<Order> {
        return httpClient.get(OrdersResource()).body()

    }

    override suspend fun update(params: Order): Order? {
        return params
    }

    override suspend fun delete(id: String): Boolean {
        return false
    }
}

class OrdersRepository(dataSource: OrdersNetworkDataSource) : BaseFlowRepository<Order>(dataSource)

class CreateOrderUseCase(
    private val ordersRepository: OrdersRepository,
    private val cartRepository: CartRepository,
) : NonParameterizedUseCase<Boolean>() {
    override suspend fun invoke(params: EmptyParams): Result<Boolean> {
        try {
            ordersRepository.create(
                Order(items = cartRepository.getCart().items.map { entity ->
                    OrderItemEntity(entity.variantId, entity.count)
                })
            )
            cartRepository.saveCart(CartEntity(emptyList()))

            return Result.Success(true)
        } catch (e: Exception) {
            return Result.Error(ServerException(e.message.orEmpty()))
        }
    }
}