package ru.workinprogress.shop

data class ServerConfig(
    val name: String,
    val scheme: String,
    val host: String,
    val development: Boolean = false,
    val port: String? = null,
)

val staging = ServerConfig(
    "Staging",
    "https",
    "shop-api.kotlin.website"
)

val local = ServerConfig(
    "Local",
    "http",
    "localhost",
    true,
    "8080"
)

val currentServerConfig: ServerConfig = staging
