package ru.workinprogress.shop

import androidx.compose.animation.*
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.ShoppingCart
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.testTag
import androidx.compose.ui.unit.IntOffset
import androidx.navigation.NavHostController
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import org.jetbrains.compose.ui.tooling.preview.Preview
import org.koin.compose.KoinApplication
import org.koin.core.module.Module
import ru.workinprogress.components.MainAppBarState
import ru.workinprogress.components.MainAppBar
import ru.workinprogress.shop.navigation.AppNavHost
import ru.workinprogress.shop.navigation.ShopScreen
import ru.workinprogress.shop.navigation.title
import ru.workinprogress.shop.theme.AppTheme
import kotlin.math.roundToInt

@Composable
@Preview
fun App(
    modifier: Modifier = Modifier,
    platformModules: List<Module> = emptyList(),
    navController: NavHostController = rememberNavController(),
    onBackClicked: () -> Unit = {
        navController.popBackStack()
    }
) {
    KoinApplication({
        modules(appModules + platformModules)
    }) {
        val keyboardController = LocalSoftwareKeyboardController.current

        AppTheme {
            ShopApp(
                modifier,
                navController,
                onBackClicked = {
                    keyboardController?.hide()
                    onBackClicked()
                })
        }
    }
}

@Composable
fun ShopApp(
    modifier: Modifier = Modifier,
    navController: NavHostController = rememberNavController(),
    appBarState: MainAppBarState = remember { MainAppBarState() },
    snackBarHostState: SnackbarHostState = remember { SnackbarHostState() },
    onBackClicked: () -> Unit
) {
    val backStackEntry by navController.currentBackStackEntryAsState()

    val currentScreen = try {
        ShopScreen.valueOf(backStackEntry?.destination?.route ?: ShopScreen.Preload.name)
    } catch (e: Exception) {
        ShopScreen.Product
    }

    LaunchedEffect(backStackEntry) {
        appBarState.showBack.value = navController.previousBackStackEntry != null
        appBarState.closeContextMenu()
    }

    LaunchedEffect(currentScreen) {
        appBarState.title.value = currentScreen.title()
    }

    Scaffold(
        modifier = modifier.fillMaxSize(),
        topBar = {
            Column(modifier = Modifier.animateContentSize()) {
                MainAppBar(appBarState) {
                    onBackClicked()
                }
            }
        },
        containerColor = MaterialTheme.colorScheme.surface,
        snackbarHost = { SnackbarHost(snackBarHostState) },
        floatingActionButton = {
            AnimatedVisibility(
                currentScreen == ShopScreen.Main,
                exit = fadeOut() + slideOut(
                    targetOffset = {
                        IntOffset(
                            0, (it.height / 2f).roundToInt()
                        )
                    }), enter = fadeIn() + slideIn(
                    initialOffset = {
                        IntOffset(
                            0, (it.height / 2f).roundToInt()
                        )
                    })
            ) {
                FloatingActionButton(
                    onClick = {
                        navController.navigate(ShopScreen.Cart.name)
                    },
                    modifier = Modifier.navigationBarsPadding().testTag("fab")
                ) {
                    Icon(
                        imageVector = Icons.Filled.ShoppingCart,
                        contentDescription = "cart",
                    )
                }
            }
        }) { padding ->
        AppNavHost(
            modifier = Modifier.consumeWindowInsets(padding).padding(top = padding.calculateTopPadding()),
            navController = navController,
            appBarState = appBarState,
            snackbarHostState = snackBarHostState,
            onBackClicked
        )
    }
}



