package ru.workinprogress.feature.auth.data

import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.coroutines.getStringStateFlow
import com.russhwolf.settings.get
import com.russhwolf.settings.observable.makeObservable
import com.russhwolf.settings.set
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import ru.workinprogress.data.AppName
import ru.workinprogress.feature.auth.Tokens
import ru.workinprogress.feature.auth.domain.TokenRepository

@OptIn(ExperimentalSettingsApi::class)
class TokenRepositoryCommon(val appName: AppName) : TokenRepository {
    private val settings: Settings = Settings()

    override fun getToken(): Tokens {
        return Tokens(
            settings[Tokens::accessToken.name + appName, ""],
            settings[Tokens::refreshToken.name + appName, ""]
        )
    }

    override fun set(accessToken: String, refreshToken: String) {
        settings[Tokens::accessToken.name + appName] = accessToken
        settings[Tokens::refreshToken.name + appName] = refreshToken
    }

    override fun observeToken(): Flow<Tokens> {
        val coroutineScope = CoroutineScope(Dispatchers.Default)

        return combine(
            settings.makeObservable().getStringStateFlow(
                coroutineScope,
                Tokens::accessToken.name + appName,
                ""
            ),
            settings.makeObservable().getStringStateFlow(
                coroutineScope,
                Tokens::refreshToken.name + appName,
                ""
            )
        ) { accessToken, refreshToken ->
            Tokens(accessToken, refreshToken)
        }
    }
}