package ru.workinprogress.feature.productDetails

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Arrangement.Absolute.spacedBy
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.FilterChip
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.fastFirstOrNull
import androidx.lifecycle.ViewModel
import androidx.lifecycle.compose.collectAsStateWithLifecycle
import androidx.lifecycle.viewModelScope
import kotlinx.collections.immutable.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import org.koin.compose.module.rememberKoinModules
import org.koin.compose.viewmodel.koinViewModel
import org.koin.core.module.dsl.viewModel
import org.koin.dsl.module
import ru.workinprogress.components.LoadingButton
import ru.workinprogress.feature.cart.CartEntity
import ru.workinprogress.feature.cart.CartItemEntity
import ru.workinprogress.feature.cart.GetCartUseCase
import ru.workinprogress.feature.cart.SaveCartUseCase
import ru.workinprogress.feature.products.Option
import ru.workinprogress.feature.products.Product
import ru.workinprogress.feature.products.Variant
import ru.workinprogress.feature.products.domain.GetProductUseCase
import ru.workinprogress.format
import ru.workinprogress.useCase.UseCase


data class ProductDetailsUiState(
    val name: String = "",
    val description: String = "",
    val options: ImmutableList<Option> = persistentListOf(),


    val optionValues: ImmutableMap<String, String> = persistentMapOf(),

    val activeVariant: Variant? = null,
    val inCart: Int = 0,
)

class ProductDetailsViewModel(
    private val productId: String,
    private val getProductUseCase: GetProductUseCase,
    private val getCartUseCase: GetCartUseCase,
    private val saveCartUseCase: SaveCartUseCase,
) : ViewModel() {

    private val state = MutableStateFlow(ProductDetailsUiState())
    val observe = state.asStateFlow()

    private lateinit var product: Product
    private lateinit var cartEntity: CartEntity

    init {
        viewModelScope.launch {
            val productResult = getProductUseCase(productId)
            val cartResult = getCartUseCase()

            if (cartResult is UseCase.Result.Success<CartEntity> && productResult is UseCase.Result.Success<Product>) {
                product = productResult.data
                cartEntity = cartResult.data

                state.update {
                    ProductDetailsUiState(
                        product.name,
                        product.description,
                        product.options.toImmutableList(),
                    )
                }
            }
        }
    }

    fun onOptionValueSelected(option: String, value: String) {
        state.update { state ->
            val values = state.optionValues.toMutableMap()
                .apply {
                    set(option, value)
                }.toImmutableMap()

            val activeVariant = product.variants.fastFirstOrNull { variant ->
                variant.optionValues == values
            }

            state.copy(
                optionValues = values,
                activeVariant = activeVariant,
                inCart = cartEntity.items.fastFirstOrNull { entity ->
                    entity.variantId == activeVariant?.id
                }?.count ?: 0
            )
        }
    }

    val activeCartItem
        get() = cartEntity.items.fastFirstOrNull { entity ->
            entity.variantId == state.value.activeVariant?.id
        }

    val activeCartItemIndex
        get() = cartEntity.items.indexOfFirst { entity ->
            entity.variantId == state.value.activeVariant?.id
        }

    fun onOrderClicked() {
    }

    fun onAddToCartClicked() {
        state.value.activeVariant?.let { activeVariant ->
            cartEntity = CartEntity(
                cartEntity.items.toMutableList().apply {
                    if (activeCartItemIndex < 0) {
                        add(
                            with(activeCartItem ?: CartItemEntity(activeVariant.id, 0)) {
                                copy(count = count + 1)
                            })
                    } else {
                        set(
                            activeCartItemIndex,
                            with(activeCartItem ?: CartItemEntity(activeVariant.id, 0)) {
                                copy(count = count + 1)
                            })
                    }


                }.toImmutableList()
            )

            viewModelScope.launch {
                saveCartUseCase.invoke(cartEntity)
            }
        }

        state.update { state ->
            state.copy(
                inCart = activeCartItem?.count ?: 0
            )
        }
    }

    fun onRemoveFromCartClicked() {
        state.value.activeVariant?.let { activeVariant ->
            cartEntity = CartEntity(
                cartEntity.items.toMutableList().apply {
                    set(
                        indexOf(activeCartItem),
                        with(activeCartItem ?: CartItemEntity(activeVariant.id, 0)) {
                            copy(count = count - 1)
                        })
                }.toImmutableList()
            )

            viewModelScope.launch {
                saveCartUseCase.invoke(cartEntity)
            }
        }

        state.update { state ->
            state.copy(
                inCart = activeCartItem?.count ?: 0
            )
        }
    }

}


@Composable
fun ProductDetails(productId: String) {
    rememberKoinModules {
        listOf(module {
            viewModel { ProductDetailsViewModel(productId, get(), get(), get()) }
        })
    }

    val viewModel = koinViewModel<ProductDetailsViewModel>()
    val state by viewModel.observe.collectAsStateWithLifecycle()


    Column(modifier = Modifier.padding(16.dp), verticalArrangement = spacedBy(8.dp)) {
        Text(state.name, style = MaterialTheme.typography.headlineMedium)
        Text(state.description, style = MaterialTheme.typography.bodyMedium)
        state.options.forEach { option ->
            Column {
                Text(option.name)

                Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    option.values.forEach { value ->
                        FilterChip(
                            state.optionValues[option.name] == value, {
                                viewModel.onOptionValueSelected(option.name, value)
                            }, { Text(value) })
                    }
                }
            }
        }

        state.activeVariant?.let { variant ->
            Text(variant.price.format(0) + " $ instock:#${variant.inStock}")
        }

        Text("Incart ${state.inCart}")

        Button({ viewModel.onAddToCartClicked() }) {
            Text("+")
        }

        Button({ viewModel.onRemoveFromCartClicked() }) {
            Text("-")
        }
        LoadingButton {
            viewModel.onOrderClicked()
        }

    }
}