package ru.workinprogress.feature.products.ui

import androidx.compose.ui.util.fastMapIndexed
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.collections.immutable.toImmutableList
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import ru.workinprogress.feature.categories.Category
import ru.workinprogress.feature.categories.domain.AddCategoryUseCase
import ru.workinprogress.feature.categories.domain.GetCategoriesUseCase
import ru.workinprogress.feature.products.CreateCategoryParams
import ru.workinprogress.feature.products.CreateProductParams
import ru.workinprogress.feature.products.ui.model.CreateProductUiState
import ru.workinprogress.feature.products.domain.CreateProductUseCase
import ru.workinprogress.feature.products.Option
import ru.workinprogress.feature.products.Variant
import ru.workinprogress.useCase.UseCase
import kotlin.collections.plus

class CreateProductViewModel(
    private val getCategoriesUseCase: GetCategoriesUseCase,
    private val createProductUseCase: CreateProductUseCase,
    private val addCategoryUseCase: AddCategoryUseCase,
) : ViewModel() {

    private val state = MutableStateFlow(CreateProductUiState())
    val observe = state.asStateFlow()

    init {
        viewModelScope.launch {
            getCategoriesUseCase.get().collectLatest { value ->
                state.update { it.copy(categories = value.toImmutableList()) }
            }
        }
    }

    fun onNameChanged(name: String) = state.update { it.copy(name = name) }
    fun onDescriptionChanged(description: String) = state.update { it.copy(description = description) }
    fun onCategorySelected(category: Category?) = state.update { it.copy(category = category) }

    fun onCreateClicked() {
        viewModelScope.launch {
            val result = createProductUseCase(
                CreateProductParams(
                    name = state.value.name,
                    categoryId = state.value.category?.id.orEmpty(),
                    options = state.value.options,
                    description = state.value.description,
                    variants = state.value.variants
                )
            )
            when (result) {
                is UseCase.Result.Error<*> -> state.update {
                    it.copy(
                        loading = false, errorMessage = result.error.message
                    )
                }

                is UseCase.Result.Success<*> -> state.update {
                    it.copy(
                        loading = false,
                    )
                }
            }
        }
    }

    fun onCategoryCreate(name: String) {
        viewModelScope.launch {
            state.update { it.copy(loading = true) }

            val result = addCategoryUseCase(CreateCategoryParams(name))
            when (result) {
                is UseCase.Result.Error<*> -> {
                    state.update { it.copy(loading = false, errorMessage = result.error.message) }
                }

                is UseCase.Result.Success<*> -> {
                    state.update { it.copy(loading = false) }
                }
            }
        }
    }

    fun onCreateOptionClicked() {
        state.update { it.copy(options = (it.options + Option("", listOf())).toImmutableList()) }
    }

    fun onOptionDelete(option: Option) {
        state.update { it.copy(options = (state.value.options - option).toImmutableList()) }
    }

    fun onOptionSaved(position: Int, option: Option) {
        state.update { state ->
            state.copy(
                options = state.options.fastMapIndexed { index, item ->
                    if (index == position) {
                        option
                    } else item
                }.toImmutableList()
            )
        }
    }

    fun onVariantPriceChanged(variant: Variant, price: Double) {
        state.update { state ->
            state.copy(variants = state.variants.map {
                if (it.id == variant.id) {
                    it.copy(price = price)
                } else {
                    it
                }
            }.toImmutableList())
        }
    }

    fun onVariantInStockChanged(variant: Variant, inStock: Int) {
        state.update { state ->
            state.copy(variants = state.variants.map {
                if (it.id == variant.id) {
                    it.copy(inStock = inStock)
                } else {
                    it
                }
            }.toImmutableList())
        }
    }

    fun onCreateVariantClicked() {
        state.update {
            it.copy(
                variants = (it.variants + Variant(
                    "${it.variants.size + 10}temp",
                    0.0,
                    0,
                    emptyMap()
                )).toImmutableList()
            )
        }
    }

    fun onVariantOptionValueSelected(variant: Variant, optionName: String, string: String) {
        state.update { state ->
            state.copy(variants = state.variants.map {
                if (it.id == variant.id) {
                    it.copy(optionValues = it.optionValues.toMutableMap().apply {
                        set(optionName, string)
                    })
                } else {
                    it
                }
            }.toImmutableList())
        }

    }
}