package ru.workinprogress.feature.categories.domain

import ru.workinprogress.feature.categories.Category
import ru.workinprogress.feature.categories.data.CategoriesRepository
import ru.workinprogress.feature.products.CreateCategoryParams
import ru.workinprogress.useCase.UseCase

class AddCategoryUseCase(private val repository: CategoriesRepository) : UseCase<CreateCategoryParams, Category>() {
    override suspend operator fun invoke(params: CreateCategoryParams): Result<Category> {
        return withTry { repository.create(Category("", params.name)) }
    }
}