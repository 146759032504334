package ru.workinprogress.shop.navigation

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.ui.Modifier
import androidx.lifecycle.compose.collectAsStateWithLifecycle
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.toRoute
import org.koin.compose.koinInject
import ru.workinprogress.components.MainAppBarState
import ru.workinprogress.feature.auth.Tokens
import ru.workinprogress.feature.auth.domain.TokenRepository
import ru.workinprogress.feature.auth.ui.component.LoginComponent
import ru.workinprogress.feature.auth.ui.component.SignupComponent
import ru.workinprogress.feature.cart.CartComponent
import ru.workinprogress.feature.productDetails.ProductDetails
import ru.workinprogress.feature.products.ProductsRoute
import ru.workinprogress.feature.products.ui.ProductsComponent
import ru.workinprogress.navigateAndClean

@Composable
@NonRestartableComposable
fun AppNavHost(
    modifier: Modifier = Modifier,
    navController: NavHostController,
    appBarState: MainAppBarState,
    snackbarHostState: SnackbarHostState,
    onBackClicked: () -> Unit,
) {
    val tokenRepository = koinInject<TokenRepository>()
    val tokenState = tokenRepository.observeToken().collectAsStateWithLifecycle(Tokens("", ""))
    val isAuth = derivedStateOf { tokenState.value.refreshToken?.isNotEmpty() == true }

    NavHost(
        navController = navController,
        startDestination = if (isAuth.value) ShopScreen.Main.name else ShopScreen.Login.name,
        modifier = Modifier.fillMaxSize().then(modifier)
    ) {
        composable(ShopScreen.Main.name) {
            ProductsComponent(appBarState, snackbarHostState) {
                navController.navigate(ProductsRoute(it.id))
            }
        }

        composable(
            ShopScreen.SignUp.name
        ) {
            SignupComponent({
                navController.popBackStack()
            }, {
                navController.popBackStack()
            })
        }

        composable(
            ShopScreen.Login.name,
        ) {
            LoginComponent(
                appBarState,
                {
                    navController.navigate(ShopScreen.SignUp.name)
                }) {
                navController.navigateAndClean(ShopScreen.Main.name)
            }
        }

        composable(ShopScreen.Cart.name) {
            CartComponent()
        }

        composable<ProductsRoute> {
            val productRoute = it.toRoute<ProductsRoute>()
            ProductDetails(productRoute.id)
        }
    }
}