package ru.workinprogress.feature.cart

import kotlinx.serialization.Serializable
import ru.workinprogress.feature.products.Variant

@Serializable
data class CartItemEntity(
    val variantId: String,
    val count: Int,
)

@Serializable
data class CartItem(
    val variant: Variant,
    val count: Int,
)