package ru.workinprogress.feature.orders

import io.ktor.resources.*

@Resource("orders")
class OrdersResource {

    @Resource("{id}")
     class ById(val parent: OrdersResource = OrdersResource(), val id: String)

}