package ru.workinprogress.feature.products

import org.koin.core.module.dsl.singleOf
import org.koin.core.module.dsl.viewModelOf
import org.koin.dsl.module
import ru.workinprogress.feature.products.domain.*
import ru.workinprogress.feature.products.ui.CreateProductViewModel
import ru.workinprogress.feature.products.ui.ProductsViewModel

val productsModule = module {
    singleOf(::GetProductsUseCase)
    singleOf(::GetProductUseCase)
    singleOf(::CreateProductUseCase)

    singleOf(::ProductsNetworkDataSource)
    singleOf(::ProductsRepository)
    viewModelOf(::ProductsViewModel)
    viewModelOf(::CreateProductViewModel)
}