import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import kotlinx.browser.document
import org.jetbrains.skiko.wasm.onWasmReady
import ru.workinprogress.shop.App

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        CanvasBasedWindow("Shop App") {
//            val navController = rememberNavController()
//            BrowserNavigator(navController)

            LaunchedEffect("") {
                document.getElementById("progressBar")?.let { element ->
                    document.body?.removeChild(element)
                }
            }

            App(
//                onBackClicked = { history.back() },
//                navController = navController
            )
        }
    }
}