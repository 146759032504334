package ru.workinprogress.feature.products

import kotlinx.serialization.Serializable

@Serializable
data class Variant(
    val id: String,
    val price: Double,
    val inStock: Int,
    val optionValues: Map<String, String>,
)