package ru.workinprogress.feature.products.ui.model

import kotlinx.collections.immutable.ImmutableList
import kotlinx.collections.immutable.persistentListOf
import ru.workinprogress.feature.categories.Category
import ru.workinprogress.feature.products.Option
import ru.workinprogress.feature.products.Variant

data class CreateProductUiState(
    val name: String = "",
    val description: String = "",
    val errorMessage: String? = null,
    val loading: Boolean = false,

    val categories: ImmutableList<Category> = persistentListOf(),
    val options: ImmutableList<Option> = persistentListOf(Option("Color", listOf("Red", "Green"))),
    val variants: ImmutableList<Variant> = persistentListOf(Variant("", 0.0, 1, mapOf("Color" to "Red"))),
    val category: Category? = null,
    val optionsCount: Int = 0,
) {
    val valid get() = name.isNotEmpty() && category != null
}