package ru.workinprogress.feature.cart

import kotlinx.serialization.Serializable

@Serializable
data class CartEntity(
    val items: List<CartItemEntity>,
)

@Serializable
data class Cart(
    val items: List<CartItem>,
)