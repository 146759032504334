package ru.workinprogress.feature.products.ui

import androidx.compose.foundation.clickable
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.ListItem
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.lifecycle.compose.collectAsStateWithLifecycle
import org.koin.compose.viewmodel.koinViewModel
import ru.workinprogress.components.MainAppBarState
import ru.workinprogress.components.ShowAppBarOnLaunch
import ru.workinprogress.feature.products.Product


@Composable
fun ProductsComponent(
    appBarState: MainAppBarState = remember { MainAppBarState() },
    snackbarHostState: SnackbarHostState = remember { SnackbarHostState() },
    onProductClicked: (Product) -> Unit,
) {
    val viewModel = koinViewModel<ProductsViewModel>()
    val state = viewModel.observe.collectAsStateWithLifecycle()
    ShowAppBarOnLaunch(appBarState)
    LaunchedEffect(state.value.errorMessage) {
        state.value.errorMessage?.let {
            snackbarHostState.showSnackbar(it)
        }
    }

    LazyColumn {
        items(state.value.products) { item ->
            ListItem(
                headlineContent = { Text(item.name) },
                supportingContent = { Text(item.options.joinToString("")) },
                modifier = Modifier.clickable(
                    onClick = {
                        onProductClicked(item)
                    })
            )
        }
    }
}