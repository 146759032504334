package ru.workinprogress.feature.products.ui

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.collections.immutable.toImmutableList
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import ru.workinprogress.feature.products.domain.GetProductsUseCase
import ru.workinprogress.feature.products.ui.model.ProductsUiState
import ru.workinprogress.useCase.UseCase

class ProductsViewModel(
    private val getProductsUseCase: GetProductsUseCase,
) : ViewModel() {

    private val state = MutableStateFlow(ProductsUiState())
    val observe = state.asStateFlow()

    init {
        viewModelScope.launch {
            val result = getProductsUseCase()

            when (result) {
                is UseCase.Result.Error -> {
                    state.update { state ->
                        state.copy(loading = false, errorMessage = result.error.message)
                    }
                }

                is UseCase.Result.Success -> {
                    state.update { state ->
                        state.copy(loading = false, products = result.data.toImmutableList())
                    }
                }
            }
        }
    }
}