package ru.workinprogress.feature.products

import kotlinx.serialization.Serializable

@Serializable
data class Option(
    val name: String,
    val values: List<String>,
)

@Serializable
data class OptionValue(
    val name: String,
    val value: String,
)