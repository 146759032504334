package ru.workinprogress.feature.products

import kotlinx.serialization.Serializable
import ru.workinprogress.feature.categories.Category
import ru.workinprogress.flowRepository.Idie

@Serializable
data class Product(
    override val id: String,
    val name: String,
    val description: String,
    val category: Category,
    val options: List<Option>,
    val variants: List<Variant>,
) : Idie