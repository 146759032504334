package ru.workinprogress.feature.orders

import kotlinx.serialization.Serializable
import ru.workinprogress.flowRepository.Idie

@Serializable
data class Order(
    override val id: String = "",
    val status: OrderStatus = OrderStatus.New,
    val items: List<OrderItemEntity>,
) : Idie

@Serializable
sealed class OrderStatus() {
    @Serializable
    data object New : OrderStatus()
    @Serializable
    data object Approved : OrderStatus()
    @Serializable
    data class Rejected(val reason: String) : OrderStatus()
    @Serializable
    data object Delivery : OrderStatus()
    @Serializable
    data object Done : OrderStatus()
    @Serializable
    data class Returned(val reason: String) : OrderStatus()
}
